var exports = {};

/*eslint-disable @mysticatea/prettier */
const {
  AbortController,
  AbortSignal
} = typeof self !== "undefined" ? self : typeof window !== "undefined" ? window :
/* otherwise */
undefined;
/*eslint-enable @mysticatea/prettier */

exports = AbortController;
exports.AbortSignal = AbortSignal;
exports.default = AbortController;
export default exports;
const _AbortSignal = exports.AbortSignal;
export { _AbortSignal as AbortSignal };